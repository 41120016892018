import React from "react"
import { Link } from "gatsby"
import Div100vh from "react-div-100vh"

export default function workday() {
  return (
    <Div100vh id="entire">
      <header>
        <nav>
          <ul id="headerMenu">
            <li className="menuItem" id="name">
              <Link to="/">hannah s. kim</Link>
            </li>
            <li className="menuItem" id="about">
              <Link to="/about">about</Link>
            </li>
          </ul>
        </nav>
      </header>
      <div className="hero">
        <div className="projectText">
          <h1 className="heroSectionProjectTitles">Workday: Pay On-Demand</h1>
          <p>
            Contact me at{" "}
            <a href="mailto:hello@hannahs.kim" class="externalLink" id="email">
              hello@hannahs.kim
            </a>{" "}
            with your questions!
          </p>
          <h1 className="heroSectionTitles">Background</h1>
          <p>
            In September 2020, Workday launched Pay On-Demand, which gives
            employees the ability to request earned wages ahead of their regular
            payday at no additional cost. I was the UX researcher on the Pay
            On-Demand team comprising of design, product, and engineering.
          </p>
          <h1 className="heroSectionTitles">Objective &#38; Work</h1>
          <p>
            The purpose of this work was to understand customers’ mental models
            of Pay On-Demand and to gather design feedback. Since it is a
            payroll feature, it was especially important to balance the
            expectations of both the payroll administrators and employees. There
            were 3 rounds of research (where I conducted interviews and concept
            testing) and design.
          </p>
          <p>
            After each round of research, I worked with our lead designer Youna
            Choi to determine and show the most critical information in the
            designs. The research informed decisions such as 1) removing an
            approval process for payroll administrators and 2) limiting Pay
            On-Demand requests to just the current pay period. An approval
            process would have added to administrators' workloads and led to
            longer pay delivery times for employees. Making requests available
            across multiple pay periods would have increased the amount of pay
            available for Pay On-Demand requests. However, fluctuations in the
            available amount (due to payroll processes I won’t dive into here)
            caused too much confusion to make having more pay available a
            worthwhile trade-off.
          </p>
          <h1 className="heroSectionTitles">Result &#38; Reflection</h1>
          <p>
            The UX team successfully delivered the designs for an early adopter
            launch of Pay On-Demand in September 2020 (see{" "}
            <a
              href="https://blog.workday.com/en-us/2020/ways-to-support-frontline-workers-financial-wellbeing.html"
              rel="noreferrer noopener"
              target="_blank"
              className="externalLink"
            >
              HERE
            </a>{" "}
            for a launch blog post). Reflecting upon this project, I would have
            prioritized involving our engineers earlier in our process. With
            their later involvement, getting buy-in for our final designs was
            more difficult, since they lacked context on already completed work.
          </p>
        </div>
        <div className="workSamples">
          <h1 className="heroSectionProjectTitles">Other Work</h1>
          <ul>
            <li className="sectionListItem" id="workdayLastListItem">
              <Link to="/cmuhcii">
                ■ Carnegie Mellon's Human-Computer Interaction Institute:
                "Tabhoarding" Research
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <footer>
        <ul id="footerLinks">
          <li>
            <a
              href="mailto: hello@hannahs.kim"
              className="footerItem"
              id="email"
            >
              hello@hannahs.kim→
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/hannahs-kim"
              className="footerItem"
              id="linkedIn"
            >
              My LinkedIn→
            </a>
          </li>
        </ul>
      </footer>
    </Div100vh>
  )
}
